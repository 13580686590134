import {Drawer} from "@mui/material";
import {useEffect, useState} from "react";
import Link from "next/link";
import i18next from "i18next";
import CookieConsentDrawer from "./Drawer";
import cookieConsentService from "../../services/cookie/cookieConsentService";

export default function CookieConsent() {
    const cookie: any = cookieConsentService.cookie();
    const [open, setOpen] = useState(!cookie || !cookie.closed);

    useEffect(() => {
        if (!cookie) cookieConsentService.defaultCookie();
        loadAnalytics();
        loadAds();
    }, [])

    const links: any = {
        de: '/s/datenschutz-7',
        en: '/s/data-protection-79',
        fr: '/s/protection-des-donnees-71'
    }

    const renderLink = (className: String = '') => {
        return (
            <Link href={`/${i18next.language}${links[i18next.language]}`}
                  target={"_blank"}
                  className={`text-decoration-underline ${className}`}>
                {i18next.t('cookieConsent.learnMore').toString()}
            </Link>
        )
    }

    const renderButton = (className: String = '') => {
        return (
            <button className={`btn btn-black text-white pt-1 pb-1 ps-4 pe-4 ${className} fw-normal`}
                    onClick={() => closeClickHandler()}>
                {i18next.t('general.close').toString()}
            </button>
        )
    }

    const closeClickHandler = () => {
        cookieConsentService.changeValue('closed', true);
        setOpen(false);
    }

    const loadAnalytics = () => {
        const cookie = cookieConsentService.cookie();
        if (!cookie.analytics) return;
        cookieConsentService.loadAnalytics();
    }

    const loadAds = () => {
        const cookie = cookieConsentService.cookie();
        if (!cookie.ads) return;
        cookieConsentService.loadTargeting();
    }

    return (
        <Drawer
            anchor={"bottom"}
            open={open}
            variant="persistent"
            onClose={() => setOpen(false)}>
            <div className={"d-md-flex flex-row justify-content-center align-items-center gap-4 pt-3 pb-3 ps-3 pe-3"}>
                <div>{i18next.t('cookieConsent.title').toString()}</div>
                {renderLink('d-none d-md-block')}
                <CookieConsentDrawer className={'d-none d-md-block'}/>
                {renderButton('d-none d-md-block')}
                {renderLink('d-block d-md-none mt-3 mb-3')}
                <div className={"d-flex d-md-none flex-row justify-content-start gap-3 mt-2"}>
                    <CookieConsentDrawer className={'d-md-none'}/>
                    {renderButton()}
                </div>
            </div>
        </Drawer>
    )
}