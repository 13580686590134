import React from "react";
import {Switch as MuiSwitch} from "@mui/material";

interface Props {
    checked: boolean;
    onChange: any;
    disabled?: boolean;
}

export default function Switch({checked, onChange, disabled = false}: Props): JSX.Element {
    return (
        <MuiSwitch
            checked={checked}
            onChange={(e: any) => onChange(e.target.checked)}
            disabled={disabled}
            sx={{
                '& .MuiSwitch-switchBase': {
                    color: 'white',
                    '&:hover': {
                        backgroundColor: 'rgba(255,255,255,0.1)',
                    },
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#000',
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                    },
                },
                '& .MuiSwitch-track': {
                    backgroundColor: '#fff',
                    border: '1px solid #000',
                    opacity: 1,
                },
                '& .MuiSwitch-track.Mui-checked': {
                    backgroundColor: '#000',
                    border: '1px solid ',
                },
            }}
        />
    )
}