import React, {useState} from "react";
import Switch from "../../form/Switch";
import i18next from "i18next";

export default function CookieContentRequired() {
    const [checked, setChecked] = useState(true);

    const changeHandler = (value: boolean) => {}

    return (
        <>
            <div className={"d-flex flex-row gap-2 align-items-center"}>
                <div className={""}>
                    <Switch checked={checked} onChange={changeHandler} disabled={true}/>
                </div>
                <div className={""}>
                    <span className={"fw-bold"}>{i18next.t('cookieConsent.requiredTitle').toString()}</span>
                </div>
            </div>
            <div className={"p-3"} dangerouslySetInnerHTML={{__html: i18next.t('cookieConsent.requiredDescription')}}/>
        </>
    )
}