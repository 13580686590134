import Header from "./Header";
import Head from 'next/head'
import PromotionAdBanner from "../promotion/AdBanner";
import React from "react";
import Footer from "./Footer/Footer";
import SeoText from "../seo/SeoText";
import NavigationMain from "../navigation/Main";
import MetaTags from "../seo/MetaTags";
import LoginOverlay from "../login/Overlay";
import WishlistOverlay from "../wishlist/ListOverlay";
import MessageBar from "../message/Bar";
import BxDetailAdsOffCanvas from "../boxalino/detail/AdsOffCanvas";
import MuiThemeProvider from "../mui/theme/provider";
import {withRouter} from "next/router";
import SearchResultOffCanvas from "../search/Result/Offcanvas";
import loadable from "@loadable/component";
import BxInspect from "../boxalino/Inspect";
import AdContainer from "../promotion/AdContainer";
import PromotionPopUp from "../promotion/PopUp";
import CookieConsent from "../cookie/Consent";
const Newsletter = loadable(() => import("../promotion/Newsletter"));

function Layout({context, bxRecommendations, children, router, title, noIndex, noFollow}: any) {
    const metaTags = context.metaTags;
    const titleEnd = 'PerfectHair.ch';

    function getTitle() {
        if (title) return `${title} | ${titleEnd}`;
        if (metaTags) return metaTags.metaTitle;
        return titleEnd;
    }

    const { bxInspectionData } = context;

    if (bxInspectionData?.length) {
        return <BxInspect inspectionData={bxInspectionData} />
    }

    return (
        <>
            <Head>
                <title key="title">{getTitle()}</title>
            </Head>
            {metaTags ? <MetaTags key="metaTags" metaTags={metaTags} noIndex={noIndex} noFollow={noFollow}/> : <></>}

            <MuiThemeProvider>
                <Header logo={context.logo}/>
                <PromotionPopUp/>
                <CookieConsent/>
                <MessageBar/>
                <LoginOverlay/>
                <WishlistOverlay/>
                <SearchResultOffCanvas/>
                <BxDetailAdsOffCanvas data={bxRecommendations}/>

                <section className="content-main">
                    <NavigationMain navigation={context.mainNavigation}/>
                    <AdContainer teaserAd={context.teaserAd}/>
                    {children}
                    <Newsletter/>

                    {context.seoText && (
                        <SeoText key={context.seoText.id} object={context.seoText}/>
                    )}
                </section>

                <Footer links={context.footer}
                        topLinks={context.footerTop}
                        sideLinks={context.footerSide}/>
            </MuiThemeProvider>
        </>
    );
}

export default withRouter(Layout)
