import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import {
  closeCart,
  openCart,
  setCart,
  setIsLoading as setCartLoading,
  setOffCanvas,
} from "../../stores/cart/cartSlice";
import type { State } from "../../stores/store";
import CartItemLink from "./ItemLink";
import CartItemImage from "./ItemImage";
import cartService from "../../services/cartService";
import tagManager from "../../services/tagManager";
import apiInternal from "../../utils/apiInternal";
import BxCartRecommendations from "../boxalino/cart/Recommendations";
import CartItemQuantity from "./ItemQuantity";
import { LinearProgress, SwipeableDrawer } from "@mui/material";
import apiClient from "../../utils/apiClient";
import Image from "next/image";
import CartOffCanvasPremium from "./OffCanvas/Premium";
import { setPremiums } from "../../stores/premium/premiumSlice";
import CartOffCanvasGift from "./OffCanvas/Gift";
import CartShippingFreeProgressBar from "./shippingFree/ProgressBar";
import { Dispatch } from "@reduxjs/toolkit";
import ProductDeliverStatus from "../product/DeliveryStatus";
import Link from "next/link";
import {useRouter} from "next/router";

export default function OffcanvasCart() {
  let cart = useSelector((state: State) => state.cartState.cart);
  const offCanvas = useSelector((state: State) => state.cartState.offCanvas);
  const [showMessage, setShowMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [shippingFree, setShippingFree] = useState(0);
  const [itemShippingFree, setItemShippingFree] = useState(false);
  const [minShippingFree, setMinShippingFree] = useState(60);
  let [isLoading, setIsLoading] = useState(true);
  const dispatch: Dispatch<any> = useDispatch();
    const router = useRouter();

  useEffect(() => {
    if (!offCanvas.isOpen || !offCanvas.isLoading) return;
    const fn = async () => {
      let sf = shippingFree;
      setIsLoading(true);

      let [resCart, resShippingFree, resItemShippingFree]: any =
        await Promise.all([
          cartService.getCart(null, null, true),
          apiInternal().post("/api/v2/shipping/free"),
          apiClient.post("/api/cart/item/shippingfree"),
        ]);

      sf = parseFloat(resShippingFree.data);
      if (sf === 0) setMinShippingFree(0);
      setShippingFree(sf);
      setIsLoading(false);
      cart = resCart.cart;
      tagManager.pushCart(cart.items);
      dispatch(setCart(resCart.cart));
      dispatch(setPremiums([resCart.premiums, resCart.premiumVisible]));
      setItemShippingFree(resItemShippingFree.data);
      dispatch(setCartLoading(false));
    };

    fn().then();
  }, [offCanvas.isOpen, offCanvas.isLoading]);

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            if (router.asPath.includes('/checkout/') && !offCanvas.isOpen) return;
            offCanvas.isOpen ? dispatch(closeCart()) : dispatch(openCart());
        };

  async function onRemove(lineItem: any) {
    await cartService.removeItem(lineItem);
    tagManager.removeFromCart(lineItem);
    dispatch(setOffCanvas({ isOpen: true, isLoading: true }));
  }

  function disableError() {}

  return (
    <>
      <div className="col-3">
        <span
          className="d-block text-center"
          aria-label={"Cart"}
          role="button"
          onClick={toggleDrawer()}
        >
          <i className="fa-solid fa-cart-shopping"></i>
          <p className="sup-2 d-none d-md-block">
            {i18next.t("general.cart").toString()}
          </p>
        </span>
      </div>
      <SwipeableDrawer
        anchor={"right"}
        open={offCanvas.isOpen}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        <div className="position-relative mui-offcanvas">
          <div
            className="ps-3 pe-3 pt-4 pb-3 bg-dark text-white"
            onClick={() => dispatch(closeCart())}
            role="button"
          >
            <i className="fa-solid fa-circle-xmark"></i>
            <span className="ms-2 fw-bold">
              {i18next.t("general.close").toString()}
            </span>
          </div>

          <div className={"text-center mb-4"} style={{ height: "5px" }}>
            {isLoading && <LinearProgress color="primary" />}
          </div>

          {showMessage && (
            <div
              className={`alert position-absolute top-0 alert-success w-100 ${
                errorMessage.length && "alert-danger"
              } ${successMessage.length && "alert-success"}`}
              role="alert"
            >
              <div className="row">
                <div className="col-10">
                  {successMessage}
                  {errorMessage}
                </div>
                <div
                  className="col-2 position-relative"
                  onClick={() => disableError()}
                  role="button"
                >
                  <i className="fa-solid fa-circle-xmark fs-6 p-4 position-absolute top-50 start-50 translate-middle"></i>
                </div>
              </div>
            </div>
          )}

          {cart.items && cart.items.length ? (
            <>
              {cart.items.map((lineItem: any, index: number) => (
                <div key={lineItem.id}>
                  <div>
                    <div className="row g-3 p-3">
                      <div
                        className="col-3 text-center align-middle"
                        onClick={() => dispatch(closeCart())}
                      >
                        <CartItemImage lineItem={lineItem} />
                      </div>
                      <div className="col-9">
                        <CartItemLink
                          lineItem={lineItem}
                          onClick={() => dispatch(closeCart())}
                        >
                          <p className={"fw-bold"}>{lineItem.name}</p>
                          {lineItem.size ? (
                            <div
                              className={"d-flex flex-row align-items-start"}
                            >
                              <p>{lineItem.size}</p>
                              <ProductDeliverStatus
                                lineItem={lineItem}
                                className={"ms-2"}
                              />
                            </div>
                          ) : (
                            <ProductDeliverStatus
                              lineItem={lineItem}
                              className={"ms-2 mb-3 d-block"}
                            />
                          )}
                        </CartItemLink>
                        <CartOffCanvasGift lineItem={lineItem} cart={cart} />
                        <div className={"row"}>
                          <CartItemQuantity
                            key={`${lineItem.id}-${lineItem.quantity}`}
                            lineItem={lineItem}
                            offcanvas={true}
                            col={"col-6"}
                            colMd={"col-md-4"}
                          />
                          <div
                            className={"col-4 col-md-6 d-flex flex-row gap-2"}
                          >
                            <p className={"mt-1"}>
                              {(lineItem.price * lineItem.quantity).toFixed(2)}
                            </p>
                          </div>
                          <div className={"col-2"}>
                            {lineItem.mode !== 42 &&
                              lineItem.orderNumber !== "sw-payment" &&
                              lineItem.orderNumber !== "sw-discount" && (
                                <div
                                  role="button"
                                  onClick={() => onRemove(lineItem)}
                                  className={"text-decoration-underline"}
                                >
                                  <i className="fa-solid fa-circle-xmark me-2" />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index < cart.items.length - 1 && <hr className={"mt-0"} />}
                </div>
              ))}

              {cart.bonusVoucher && cart.bonusVoucher.map((bonusVoucher: any, index: number) => {
                const isType1 = bonusVoucher.type === 1;
                const imageSrc = i18next.t(
                  isType1
                    ? "bonusVoucher.voucherImage"
                    : "bonusVoucher.salonVoucherImage"
                );
                const label = i18next.t(
                  isType1 ? "bonusVoucher.label" : "bonusVoucher.salonLabel",
                  { value: bonusVoucher.value }
                );

                return (
                  <div className="row g-3 p-3" key={index}>
                    <div
                      className="col-3 text-center align-middle"
                      onClick={() => dispatch(closeCart())}
                    >
                      <Image
                        src={imageSrc}
                        height={80}
                        width={80}
                        style={{ maxWidth: "80px", maxHeight: "80px" }}
                        alt="Bonus Voucher"
                        className="mb-3 w-auto h-100"
                      />
                    </div>
                    <div className="col-9">
                      <p
                        className="fw-bold"
                        dangerouslySetInnerHTML={{ __html: label }}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="ms-3 me-3 divider"></div>

              <div className="row mt-4 ps-3 pe-3 mb-4">
                <div className="col-7">
                  <div className={"fw-bold"}>
                    {i18next.t("general.subTotal").toString()}
                  </div>
                </div>
                <div className="col-5 text-end">
                  <div className={"fw-bold"}>
                    {cart.total.subTotal.toFixed(2)}
                  </div>
                </div>
              </div>

              <CartShippingFreeProgressBar
                key={shippingFree}
                cart={cart}
                minShippingFree={minShippingFree}
                itemShippingFree={itemShippingFree}
                shippingFree={shippingFree}
              />

              <div
                className="ps-3 pe-3 mt-3 mb-5"
                onClick={() => dispatch(closeCart())}
              >
                <Link
                  className="btn btn-black text-white w-100"
                  href={{
                    pathname: `/${i18next.language}/checkout/confirm`,
                    query: { checkout: true },
                  }}
                >
                  {i18next.t("general.checkout").toString()}
                </Link>
              </div>

              <CartOffCanvasPremium className={"ps-3 pe-3"} />

              {cart.items && cart.items.length && offCanvas.isOpen ? (
                <BxCartRecommendations
                  key={`bx-cart-rec-${offCanvas.isLoading}`}
                  ids={cart.items.map((c: any) => c.articleId)}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            !isLoading && (
              <p className="ms-4 mt-5 mb-5">
                {i18next.t("cart.empty").toString()}
              </p>
            )
          )}
        </div>
      </SwipeableDrawer>
    </>
  );
}
