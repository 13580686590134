import React, {useState} from "react";
import Switch from "../../form/Switch";
import cookieConsentService from "../../../services/cookie/cookieConsentService";
import {initializeGoogleTagManager} from "../../../utils/googleTagManager";
import i18next from "i18next";

export default function CookieContentAds() {
    const [checked, setChecked] = useState(true);

    const changeHandler = (value: boolean) => {
        setChecked(value);
        if (value && !cookieConsentService.analytics()) {
            try {
                initializeGoogleTagManager(process.env.NEXT_PUBLIC_GTM!);
                cookieConsentService.loadTargeting();
            }
            catch(e) {
                console.error(e);
            }
        }

        cookieConsentService.changeValue('ads', value);
        if (value) return;
        cookieConsentService.unloadTargeting();
    }

    return (
        <>
            <div className={"d-flex flex-row gap-2 align-items-center"}>
                <div className={""}>
                    <Switch checked={checked} onChange={changeHandler} />
                </div>
                <div className={""}>
                    <span className={"fw-bold"}>{i18next.t('cookieConsent.adsTitle').toString()}</span>
                </div>
            </div>
            <div className={"p-3"} dangerouslySetInnerHTML={{__html: i18next.t('cookieConsent.targetingDescription')}}/>
        </>
    )
}